const $ = jQuery;

function maybeOpenFromQuery() {
    const url = new URL(window.location);
    const params = url.searchParams;

    if (params.has('switch-subscription') && params.has('item')) {
        open($('.product-subscribe-add').first().data('pid'));
    }
}

function open(pid) {
    $('.subscribe-modal[data-pid="' + pid + '"]')
        .first()
        .addClass('open');
}

export default () => {
    const $input = $('.wcsatt-options-prompt-action-input[value="yes"]').first();

    $input.attr('checked', true);

    $('.subscribe-modal').appendTo('body');

    $('.product-subscribe-add').click(function () {
        open($(this).data('pid'));

        return false;
    });

    $('.subscribe-modal-action-cancel').click(() => {
        $('.subscribe-modal').removeClass('open');
        return false;
    });

    maybeOpenFromQuery();
};
