import Glide, { Breakpoints, Autoplay, Swipe } from '@glidejs/glide/dist/glide.modular.esm';

const glideSelector = '.featured-coffee-grid';

const opts = {
    type: 'carousel',
    perView: 3,
    gap: 32,
    breakpoints: {
        660: { gap: 20 },
        560: {
            peek: 0,
            gap: 0,
            perView: 1,
            autoplay: 5000,
        },
    },
};

function resize(glide) {
    const width = window.innerWidth;

    if (width <= 560) {
        glide.enable();
    } else {
        glide.disable();
    }
}

export default function () {
    if (!document.querySelector(glideSelector)) {
        return;
    }

    addEventListener('load', () => {
        const glide = new Glide(glideSelector, opts).mount({ Breakpoints, Autoplay, Swipe });
        document.querySelector('.featured-coffee-loading').classList.remove('featured-coffee-loading');
        addEventListener('resize', (e) => resize(glide));
        resize(glide);
    });
}
