import '../styles/blendin.css';
import checkoutCoupon from './modules/checkout-coupon';
import featuredCoffee from './modules/featured-coffee';
import navigation from './modules/navigation';
import productGallery from './modules/product-gallery';
import quantityInput from './modules/quantity-input';
import shipmentTracker from './modules/shipment-tracker';
import productSubscribeModal from './modules/subscribe-modal';

document.addEventListener('DOMContentLoaded', () => {
    featuredCoffee();
    navigation();
    productGallery();
    quantityInput();
    productSubscribeModal();
    checkoutCoupon();
    shipmentTracker();
});
