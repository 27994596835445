const $ = jQuery;

function increase(wrapper) {
    wrapper.querySelector('input').stepUp();
    update(wrapper);
    enableCartUpdate(wrapper);
}

function decrease(wrapper) {
    wrapper.querySelector('input').stepDown();
    update(wrapper);
    enableCartUpdate(wrapper);
}

function updateInput(input) {
    input.style = 'width:' + input.value.length + 'ch';
}

function updateHandles(input) {
    const wrapper = input.closest('.quantity');
    const increase = wrapper.querySelector('.quantity-btn-increase');
    const decrease = wrapper.querySelector('.quantity-btn-decrease');

    increase.disabled = input.max && parseInt(input.value) >= input.max;
    decrease.disabled = parseInt(input.value) <= input.min;
}

function enableCartUpdate(wrapper) {
    const cart = wrapper.closest('table.cart');

    if (cart) {
        cart.querySelector('button[name="update_cart"]').disabled = false;
    }
}

function update(wrapper) {
    const input = wrapper.querySelector('input');
    const wholesaleProduct = wrapper.closest('.wholesale-product');

    updateInput(input);
    updateHandles(input);

    if (wholesaleProduct) {
        $(wholesaleProduct).trigger('update:qty');
    }
}

export default () => {
    $('.quantity').each((idx, wrapper) => update(wrapper));

    $(document).on('click', '.quantity-btn-increase, .quantity-btn-decrease', (event) => {
        const target = event.target;
        const wrapper = target.closest('.quantity');
        const cb = target.classList.contains('quantity-btn-decrease') ? decrease : increase;

        cb(wrapper);
    });

    $(document.body).on('wc_fragments_refreshed wc_fragments_loaded', (event) => {
        $('table.cart .quantity').each((idx, wrapper) => update(wrapper));
    });
};
