const listSelector = '.tracking-history';
const btnSelector = '.tracking-history-toggle';


function initialize() {
    const btn = document.querySelector(btnSelector);
    const list = document.querySelector(listSelector);

    btn.addEventListener('click', () => {
        list.classList.toggle('hidden');
        btn.innerHTML = list.classList.contains('hidden') ? 'Show History' : 'Hide History';
    });
}

export default function () {
    if(!document.querySelector(listSelector)) {
        return;
    }

    initialize();
}
