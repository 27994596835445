import Glide, { Controls } from '@glidejs/glide/dist/glide.modular.esm';

const glideSelector = '.product-gallery .glide';

export default function () {
    if (!document.querySelector(glideSelector)) {
        return;
    }

    new Glide(glideSelector).mount({ Controls });
}
