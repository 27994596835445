const $ = jQuery;

class CheckoutCoupon {
    constructor() {
        $('body').on('updated_checkout', () => this.bind());
    }

    bind() {
        const $form = $('.woocommerce-form-coupon');
        const $formCode = $('.woocommerce-form-coupon #coupon_code');
        const $cloneCode = $('#coupon-clone-code');
        const $cloneSubmit = $('#coupon-clone-submit');

        $cloneCode.on('input', () => $formCode.val($cloneCode.val()));
        $cloneSubmit.on('click', () => {
            $form.submit();
        });
    }
}

export default function () {
    const $form = $('form.woocommerce-form-coupon');
    const $clone = $('#coupon-clone');

    if ($form.length && $clone.length) {
        new CheckoutCoupon($form, $clone);
    }
}
